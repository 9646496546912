import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const LawWritingServicePage = () => (
  <Layout
    pageWrapperClassName="law-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Law Essay Writing Service  - Write My Law Assignment"
      meta={[
        {
          name: 'description',
          content: 'Law Assignment Writing Service at TakeAwayEssay.com 📝 is a professional law essay writing service, offering top-notch writing services to clients 24/7. Our experienced writers guarantee timely delivery and high-quality content, backed up by positive reviews from our satisfied customers.',
        },

        {
          property: 'og:title',
          content: 'Custom Law Essay Writing Service: Order Your Assignment for Sale | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Do you have a law essay to write and need someone to help you out with it? With our custom law essay writing service, you can purchase all your assignments and get the assistance of the best writers. Fill in the order form and we will contact you right away.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/law-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Set your own rules
        <br className="first-screen-section__title__br" />
        {' '}
        of essay writing
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        Our writers will follow
        <br className="first-screen-section__heading__br" />
        {' '}
        all of your requirements accurately
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Write my law assignment: <br /> law essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            Are you studying law and have a lot of paper to write? With our law essay writing
            service, you don’t have to worry about any assignments anymore.{' '}
            <strong>
              All you have to do is just send us your “do my law essay” request, and our writing
              service will start working on your homework. Find more interesting information about
              our company on this page, and make an order with us today.{' '}
            </strong>
          </p>
          <h2>Buy law essay from experts </h2>
          <p>
            We want you to get affordable assignments of the best quality possible. This is why our
            writer is going to create a paper free from plagiarism and any mistakes. Whenever you
            pay for your essay, you can expect from us a custom assignment written in excellent
            English.
          </p>
          <p>
            We guarantee you cheap prices as well as fast delivery, so you won’t have to wait long
            until your assignment is done. Our company is rated as one of the top services, so when
            you buy law essay with us online, you don’t have to worry about its quality.
          </p>
          <h2 className="h3">Why should I trust you to help me write my law essay? </h2>
          <p>
            So you think about making an order, but still not sure whether you should make it or
            not. We have got a lot to offer you. To help you make a decision, here is a list of our
            benefits and features every client gets when buying a paper with us:
          </p>
          <ul className="dot-list">
            <li>
              Professionalism. We work with writers who have enough experience and skills to assist
              students. Our custom law essay writing service is going to hire only those experts who
              have completed an interview and a few tests that show their high level of knowledge.
              You can trust our professional helper to provide you with the paper you need.
            </li>
            <li>
              Urgent essays. Whenever you order with us, you can rely on our service to deliver your
              assignments not only on time but even sooner. You will receive your assignment before
              the deadline since we want you to be sure that it is completed according to all the
              instructions you gave us.
            </li>
            <li>
              Online assistance around the clock. Do you have any questions left? Feel free to send
              us your "help me with my assignment" message. We are going to provide you with
              immediate help no matter what time it is. Our custom law essay writing service is
              ready to be there for you 24/7.
            </li>
            <li>
              Cheap rates. Save your money for something you always wanted to buy: our service
              offers the lowest prices possible for all of the papers you can get with us. On the
              website, you can calculate the price and decide whether it’s okay for you or not, and
              only then place an order. You can also find many assignments on sale from time to
              time.
            </li>
            <li>
              Any type of paper available for an order. Do you have a dissertation to write? Or
              maybe there is another term or research paper you have to complete? With us, you can
              purchase all kinds of papers, including school and college assignments.
            </li>
            <li>
              Great quality of each paper. Our team of writers will take care of your assignment
              responsibly. We’re going to assist you with the hardest tasks, and you get help to
              earn the grades you always wanted.
            </li>
            <li>
              Safety is guaranteed. Don't be afraid that some of your private data might be stolen:
              our website is fully secured, and there is zero chance of any attacks or hacks
              happening. All the chats and order details are strictly confidential.
            </li>
          </ul>
          <h2 className="h3">Pay for law essay and get authentic assignment written by professionals </h2>
          <p>
            All of the paid assignments are non-plagiarized. For you to be sure about that, you will
            receive a report card that contains the results of an anti-plagiarism program check.
            When you buy law essay online with our law essay writing service, you can trust the
            experts to deliver a legit paper to you. All the students who use our assistance share
            their positive feedback about our service, and if you are interested to find out more,
            read more online reviews about our service.
          </p>
          <h2 className="h3">Place an order on our website now </h2>
          <p>
            “Who can write my law essay?” is something many students ask. If you decide to get help
            with your homework with our service, you can easily do it on our site. Just fill in the
            application form with all the details and send it to us. We will provide you with custom
            law essays that will help you to improve your own writing so you can achieve the highest
            grades and become a successful law student. Free your time for other significant tasks
            and some rest that is especially important if you want to study productively.
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Hire our writers">
      Your loyal army of professional writers awaits.
      <br />
      {' '}
      Dictate your will.
    </CTA>

  </Layout>
);

export default LawWritingServicePage;
